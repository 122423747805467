import { GameConfigs, GameState, PanelState } from "./state/app.state";
import { GameStatus } from "../../constants/interfaces/Game";
import { ListUser } from "../../constants/interfaces/List";
import { AppState } from "./reducers";
import { ErrorMessagesState } from "../../features/errorMessages/store/state";
import { MyList } from "../../constants/interfaces/MyList";
import { TopList } from "../../constants/interfaces/TopList";
import { Profile } from "../../constants/interfaces/Profile";
import { GameHistory } from "../../constants/interfaces/GameHistory";
import { NextGame } from "../../constants/interfaces/NextGame";
import { AudioPlayerState } from "../../features/audioPlayer/store/state";
import { MessengerState } from "../../features/chat/store/state";
import { Balance } from "../../constants/interfaces/Balance";
import { ITenant } from "../../constants/interfaces/Tenant";
import { GameConfig } from "../../features/actionPanel/configs/actions";

export function SelectAppState(state: AppState) {
  return state as AppState;
}

export function SelectGameState(state: AppState) {
  return state.game as GameState;
}

export function SelectGameStatusState(state: AppState) {
  return SelectGameState(state).status as GameStatus;
}

export function SelectCurrentBetsState(state: AppState) {
  return SelectGameState(state).currentBets as ListUser[];
}

export function SelectPreviousBetsState(state: AppState) {
  return SelectGameState(state).lastBets as ListUser[];
}

export function SelectMyBetsState(state: AppState) {
  return SelectGameState(state).myBets as MyList[];
}

export function SelectMaxMinConfig(state: AppState) {
  return SelectGameState(state).minMaxValue as GameConfig;
}

export function SelectTopBetsState(state: AppState) {
  return SelectGameState(state).top as TopList;
}

export function SelectGameConfigsState(state: AppState) {
  return SelectGameState(state).configs as GameConfigs;
}

export function SelectPanelState(state: AppState) {
  return SelectGameState(state).boards as PanelState[];
}

export function SelectMessagesState(state: AppState) {
  return state.messages as ErrorMessagesState[];
}

export function SelectAudioPlayerState(state: AppState) {
  return state.audioPlayer as AudioPlayerState;
}

export function SelectMessengerState(state: AppState) {
  return state.messenger as MessengerState;
}

export function SelectProfileState(state: AppState) {
  return SelectGameState(state).profile as Profile;
}

export function SelectBalanceState(state: AppState): Balance {
  return SelectGameState(state).profile.B;
}

export function SelectGameHistoryState(state: AppState) {
  const items = SelectGameState(state).gameHistory;
  return items ? items : ([] as GameHistory[]);
}

export function SelectNextGamesState(state: AppState) {
  const items = SelectGameState(state).nextGames;
  return items ? items : ([] as NextGame[]);
}

export function SelectBoardsState(state: AppState) {
  return SelectGameState(state).boards as PanelState[];
}

export function SelectFreeBetsState(state: AppState) {
  return SelectGameState(state).freeBetsEnabled;
}

export function SelectErrorsState(state: AppState) {
  return SelectGameState(state).errors;
}

export function SelectTenantState(state: AppState): ITenant {
  return SelectGameState(state).tenant;
}
