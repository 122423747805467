import React, { FC } from "react";
import { IconProps } from "./types";

export const List: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 377 377"
    >
      <circle cx="15" cy="88.5" r="15" />
      <rect x="75" y="73.5" width="302" height="30" />
      <circle cx="15" cy="288.5" r="15" />
      <rect x="75" y="273.5" width="302" height="30" />
      <circle cx="15" cy="188.5" r="15" />
      <rect x="75" y="173.5" width="302" height="30" />
    </svg>
  );
};

export default List;
