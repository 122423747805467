import { useEffect, useState } from "react";

function useConnectionModal({
  networkModal,
  setNetworkModal,
}: {
  networkModal: boolean;
  setNetworkModal: (x: boolean) => void;
}) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);

      if (!networkModal && isOnline) {
        setNetworkModal(true);
      }
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", () => {
      setTimeout(() => handleStatusChange(), 3000);
    });

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline, setNetworkModal, networkModal]);

  return { isOnline };
}

export default useConnectionModal;
