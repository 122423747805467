import React, { FC } from "react";
import { IconProps } from "./types";

export const Lock: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 21L19 21C19.5523 21 20 20.5523 20 20L20 10C20 9.44772 19.5523 9 19 9L5 9C4.44772 9 4 9.44772 4 10L4 20C4 20.5523 4.44772 21 5 21Z"
        stroke="#6D5CCE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9H8ZM13.2538 4.62688C13.6215 5.03898 14.2537 5.07499 14.6658 4.70731C15.0779 4.33962 15.1139 3.70748 14.7462 3.29538L13.2538 4.62688ZM10 9V6.12H8V9H10ZM10 6.12C10 4.88027 10.9188 4 11.9037 4V2C9.68128 2 8 3.91347 8 6.12H10ZM11.9037 4C12.4186 4 12.8984 4.22855 13.2538 4.62688L14.7462 3.29538C14.0446 2.50903 13.0367 2 11.9037 2V4Z"
        fill="#6D5CCE"
      />
    </svg>
  );
};

export default Lock;
