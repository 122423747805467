import React, { FC } from "react";
import { IconProps } from "./types";

export const RotationIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.5" clipPath="url(#clip0_2993_22377)">
        <path
          d="M31.1812 95.0938L28.0562 80.5938C27.6937 78.9063 26.05 77.8625 24.3438 78.1937C22.6562 78.5625 21.5875 80.2188 21.95 81.9125L23.5687 89.4188C12.6937 81.8438 6.25 69.725 6.25 56.25C6.25 54.525 4.85 53.125 3.125 53.125C1.4 53.125 0 54.525 0 56.25C0 70.7812 6.8125 84.4313 18.1 93.225L9.1875 93.7563C7.4625 93.8625 6.15 95.3375 6.25625 97.0625C6.35625 98.7188 7.73125 100 9.375 100C9.4375 100 9.5 100 9.5625 99.9937L28.3125 98.8688C29.225 98.8125 30.0625 98.3688 30.6188 97.6437C31.1688 96.9188 31.375 95.9813 31.1812 95.0938Z"
          fill="white"
        />
        <path
          d="M81.8995 6.775L90.812 6.24375C92.537 6.1375 93.8495 4.6625 93.7433 2.9375C93.6433 1.28125 92.2683 0 90.6245 0C90.562 0 90.4995 0 90.437 0.00625L71.687 1.13125C70.7745 1.1875 69.937 1.63125 69.3808 2.35625C68.8308 3.08125 68.6245 4.01875 68.8183 4.90625L71.9433 19.4062C72.3058 21.0938 73.9495 22.1375 75.6558 21.8062C77.3433 21.4375 78.412 19.7812 78.0495 18.0875L76.437 10.5813C87.3058 18.1562 93.7495 30.275 93.7495 43.75C93.7495 45.475 95.1495 46.875 96.8745 46.875C98.5995 46.875 99.9995 45.475 99.9995 43.75C99.9995 29.2188 93.187 15.5687 81.8995 6.775Z"
          fill="white"
        />
        <path
          d="M89.7746 54.4187L45.5809 10.225C43.1434 7.78748 39.1809 7.78748 36.7434 10.225L10.2246 36.7437C7.78711 39.1812 7.78711 43.1437 10.2246 45.5812L54.4184 89.775C56.8559 92.2125 60.8184 92.2125 63.2559 89.775L89.7746 63.2562C92.2121 60.8187 92.2121 56.8562 89.7746 54.4187ZM54.4184 80.9375L19.0621 45.5812L45.5809 19.0625L80.9371 54.4187L54.4184 80.9375Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2993_22377">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RotationIcon;
