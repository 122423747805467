import {
  BoardActionParams,
  PlayerCashOutParams,
  PlayerRegisterParams,
  PlayerUnregisterParams,
  RegisterSuccessActionParams,
  SaveSettingsParams,
} from "../services";
import { Message } from "../../../constants/interfaces/Message";
import { NextGame } from "../../../constants/interfaces/NextGame";
import { Player } from "../../../constants/interfaces/Player";
import { Balance, FreeSpin } from "../../../constants/interfaces/Balance";
import { ErrorListNames } from "../../../components/Modals/ErrorList";
import { ETenantType } from "../../../constants/interfaces/Tenant";

export enum ACTIONS {
  PING = "PING",

  DATA_SENT = "DATA_SENT",
  DATA_RECEIVED = "DATA_RECEIVED",

  PENDING = "PENDING",
  SET_LAUNCHING = "SET_LAUNCHING",

  REGISTER = "REGISTER",
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  REGISTER_FAIL = "REGISTER_FAIL",

  CASH_OUT = "CASH_OUT",

  UNREGISTER = "UNREGISTER",
  UNREGISTER_LOCAL = "UNREGISTER_LOCAL",
  UNREGISTER_SUCCESS = "UNREGISTER_SUCCESS",
  UNREGISTER_FAIL = "UNREGISTER_FAIL",

  BALANCE_CHANGE = "BALANCE_CHANGE",

  GAME_CREATED = "GAME_CREATED",
  GAME_STARTED = "GAME_STARTED",
  GAME_CRUSHED = "GAME_CRUSHED",
  PLAYER_REGISTERED = "PLAYER_REGISTERED",
  PLAYER_CASHED_OUT = "PLAYER_CASHED_OUT",
  PLAYER_UNREGISTERED = "PLAYER_UNREGISTERED",
  CURRENT_MULTIPLIER = "CURRENT_MULTIPLIER",

  BOARD_ADDED = "BOARD_ADDED",
  BOARD_REMOVED = "BOARD_REMOVED",

  TOGGLE_ENABLE_FREE_BETS = "TOGGLE_ENABLE_FREE_BETS",

  INITIALIZE_GAME_STATE = "INITIALIZE_GAME_STATE",
  INITIALIZE_GAME_SETTINGS = "INITIALIZE_GAME_SETTINGS",

  INITIALIZE_TENANT_STATE = "INITIALIZE_TENANT_STATE",

  SAVE_GAME_SETTINGS = "SAVE_GAME_SETTINGS",
  LOAD_GAME = "LOAD_GAME",
  GAME_ERROR = "GAME_ERROR",
}

export const pingAction = () => {
  return {
    type: ACTIONS.PING as ACTIONS.PING,
  };
};

export const dataSentAction = (data: any) => {
  return {
    type: ACTIONS.DATA_SENT as ACTIONS.DATA_SENT,
    data,
  };
};

export const dataReceivedAction = (data: any) => {
  return {
    type: ACTIONS.DATA_RECEIVED as ACTIONS.DATA_RECEIVED,
    data,
  };
};

export const setLaunchingAction = () => {
  return {
    type: ACTIONS.SET_LAUNCHING as ACTIONS.SET_LAUNCHING,
  };
};

export const pendingAction = (payload: PlayerRegisterParams) => {
  return {
    type: ACTIONS.PENDING as ACTIONS.PENDING,
    payload,
  };
};

export const registerAction = (payload: PlayerRegisterParams) => {
  return {
    type: ACTIONS.REGISTER as ACTIONS.REGISTER,
    payload,
  };
};

export const registerSuccessAction = (payload: RegisterSuccessActionParams) => {
  return {
    type: ACTIONS.REGISTER_SUCCESS as ACTIONS.REGISTER_SUCCESS,
    payload,
  };
};

export const registerFailAction = (payload: RegisterSuccessActionParams) => {
  return {
    type: ACTIONS.REGISTER_FAIL as ACTIONS.REGISTER_FAIL,
    payload,
  };
};

export const cashOutAction = (payload: PlayerCashOutParams) => {
  return {
    type: ACTIONS.CASH_OUT as ACTIONS.CASH_OUT,
    payload,
  };
};

export const unregisterAction = (payload: PlayerUnregisterParams) => {
  return {
    type: ACTIONS.UNREGISTER as ACTIONS.UNREGISTER,
    payload,
  };
};

export const unregisterLocalAction = (payload: PlayerUnregisterParams) => {
  return {
    type: ACTIONS.UNREGISTER_LOCAL as ACTIONS.UNREGISTER_LOCAL,
    payload,
  };
};

export const unregisterActionSuccess = (payload: PlayerUnregisterParams) => {
  return {
    type: ACTIONS.UNREGISTER_SUCCESS as ACTIONS.UNREGISTER_SUCCESS,
    payload,
  };
};

export const unregisterActionFail = (payload: PlayerUnregisterParams) => {
  return {
    type: ACTIONS.UNREGISTER_FAIL as ACTIONS.UNREGISTER_FAIL,
    payload,
  };
};

export type PlayerBalanceChangeParams = {
  Message?: "UnRegister";
  PlayerBalance: Balance;
};

export const playerBalanceChangeAction = (
  payload: PlayerBalanceChangeParams
) => {
  return {
    type: ACTIONS.BALANCE_CHANGE as ACTIONS.BALANCE_CHANGE,
    payload,
  };
};

export interface GameCreatedParams extends Message {
  Multiplier: number;
  GameId: number;
  NextGames: NextGame[];
}

export const gameCreatedAction = (payload: GameCreatedParams) => {
  return {
    type: ACTIONS.GAME_CREATED as ACTIONS.GAME_CREATED,
    payload,
  };
};

export interface GameStartedParams extends Message {
  Multiplier: number;
  GameId: number;
}

export const gameStartedAction = (payload: GameStartedParams) => {
  return {
    type: ACTIONS.GAME_STARTED as ACTIONS.GAME_STARTED,
    payload,
  };
};

export interface GameCrashedParams extends Message {
  Multiplier: number;
  HashKey: string;
  Hash: string;
  GameId: number;
}

export const gameCrushedAction = (payload: GameCrashedParams) => {
  return {
    type: ACTIONS.GAME_CRUSHED as ACTIONS.GAME_CRUSHED,
    payload,
  };
};

export interface CurrentMultiplierParams extends Message {
  M: number;
}

export const currentMultiplierAction = (payload: CurrentMultiplierParams) => {
  return {
    type: ACTIONS.CURRENT_MULTIPLIER as ACTIONS.CURRENT_MULTIPLIER,
    payload,
  };
};

export enum PlayerRegisterErrorStatuses {
  Error = -1,
  Success = 1,
  GamblerNotActive = 2,
  NotEnoughFunds = 3,
  InvalidGameStatus = 4,
  InvalidBetAmount = 5,
  BetNotFound = 6,
  InvalidMultiplier = 7,
  // Concurrency = 10,
  PlayerNotFound = 20,
}

export interface PlayerRegisteredParams extends Message {
  BI: number;
  GID: number;
  P: Player;
  PI: number;
  MID: string;
  YouRegisteredOnGame?: {
    GameId: number;
    CurrentPlayer: {
      BuyIn: number;
      AutoCashOutOn: null | number;
      IsAutoBet: boolean;
      PanelIndex: number;
      Player: { I: number; N: string; A: string };
      BalanceType: number;
    };
    PlayerBalance: {
      WalletBalance: number;
      FreeSpinBalance: FreeSpin[];
    };
  };
  EC: PlayerRegisterErrorStatuses;
}

export const playerRegisteredAction = (payload: PlayerRegisteredParams) => {
  return {
    type: ACTIONS.PLAYER_REGISTERED as ACTIONS.PLAYER_REGISTERED,
    payload,
  };
};

export interface PlayerUnregisteredParams extends Message {
  BI: number;
  GID: number;
  P: Player;
  PI: number;
  YouUnRegisteredOnGame?: {
    GameId: number;
    CurrentPlayer: {
      BuyIn: number;
      AutoCashOutOn: null | number;
      IsAutoBet: boolean;
      PanelIndex: number;
      Player: { I: number; N: string; A: string };
      BalanceType: number;
    };
    PlayerBalance?: {
      WalletBalance: number;
      FreeSpinBalance: FreeSpin[];
    };
  };
}

export const playerUnregisteredAction = (payload: PlayerUnregisteredParams) => {
  return {
    type: ACTIONS.PLAYER_UNREGISTERED as ACTIONS.PLAYER_UNREGISTERED,
    payload,
  };
};

export interface CashedOutPlayerParams {
  BuyIn: number;
  CashOut: number;
  Multiplier: number;
  PanelIndex: number;
  Player: { I: number; N: string; A: string };
  Timestamp: string;
}

export interface PlayerCashedOutParams extends Message {
  GID: number;
  M: number;
  PI: number;
  PID: number;
  EC: number;
  YouCashedOut?: {
    GameId: number;
    CashedOutPlayer: CashedOutPlayerParams;
    PlayerBalance?: {
      WalletBalance: number;
      FreeSpinBalance: FreeSpin[];
    };
  };
}

export const playerCashedOutAction = (payload: PlayerCashedOutParams) => {
  return {
    type: ACTIONS.PLAYER_CASHED_OUT as ACTIONS.PLAYER_CASHED_OUT,
    payload,
  };
};

export const boardAddedAction = (payload: BoardActionParams) => {
  return {
    type: ACTIONS.BOARD_ADDED as ACTIONS.BOARD_ADDED,
    payload,
  };
};

export const boardRemovedAction = (payload: BoardActionParams) => {
  return {
    type: ACTIONS.BOARD_REMOVED as ACTIONS.BOARD_REMOVED,
    payload,
  };
};

export const toggleEnableFreeBetsAction = (enabled: boolean) => {
  return {
    type: ACTIONS.TOGGLE_ENABLE_FREE_BETS as ACTIONS.TOGGLE_ENABLE_FREE_BETS,
    enabled,
  };
};

type InitializeGameStateParams = {
  Multiplier: number;
  Status: 1 | 2 | 3;
  Duration: number;
  LeftDuration: number;
};

export const initializeGameStateAction = (data: InitializeGameStateParams) => {
  return {
    type: ACTIONS.INITIALIZE_GAME_STATE as ACTIONS.INITIALIZE_GAME_STATE,
    data,
  };
};

export const initializeTenantAction = (data: ETenantType) => {
  const tenant = data ?? ETenantType.Local;
  return {
    type: ACTIONS.INITIALIZE_TENANT_STATE as ACTIONS.INITIALIZE_TENANT_STATE,
    data: tenant,
  };
};

export const saveSettingsAction = (payload: SaveSettingsParams) => {
  return {
    type: ACTIONS.SAVE_GAME_SETTINGS as ACTIONS.SAVE_GAME_SETTINGS,
    payload,
  };
};

export const loadGameAction = (payload: number) => {
  return {
    type: ACTIONS.LOAD_GAME as ACTIONS.LOAD_GAME,
    payload,
  };
};

type InitializeGameSettingsParams = {
  IsChatBlocked: boolean;
  Sound: boolean;
  Music: boolean;
};

export const initializeGameSettingsAction = (
  data: InitializeGameSettingsParams
) => {
  return {
    type: ACTIONS.INITIALIZE_GAME_SETTINGS as ACTIONS.INITIALIZE_GAME_SETTINGS,
    data,
  };
};

export const gameErrorAction = (errorType: ErrorListNames) => {
  return {
    type: ACTIONS.GAME_ERROR as ACTIONS.GAME_ERROR,
    errorType,
  };
};
