import { useCallback } from "react";
import useTranslate from "../../config/language";

const MONTH_LIST = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const MONTH_LIST_LONG = [
  "January",
  "February",
  "March",
  "April",
  "MayLong",
  "JuneLong",
  "JulyLong",
  "August",
  "September",
  "Octomber",
  "November",
  "December",
];

export const formatDate = (date: string) => {
  const formatDate = new Date(date);
  const month = formatDate.getMonth();
  const day = formatDate.getDate();
  return day + " " + MONTH_LIST[month];
};

export const useFormatDate = () => {
  const translate = useTranslate();

  return useCallback(
    (date: string, long: boolean) => {
      const formatDate = new Date(date);
      const month = formatDate.getMonth();
      const day = formatDate.getDate();
      if (long) {
        return day + " " + translate(MONTH_LIST_LONG[month]);
      } else {
        return day + " " + translate(MONTH_LIST[month]);
      }
    },
    [translate]
  );
};

export const formatTime = (date: string) => {
  const formatDate = new Date(date);

  return (
    formatDate.getHours() + ":" + ("0" + formatDate.getMinutes()).slice(-2)
  );
};
