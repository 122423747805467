import React, { FC } from 'react';
import { IconProps } from './types';

export const Emoji: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 18.782 18.782"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fillRule="evenodd" fill="currentColor">
                <path d="M9.391,0a9.391,9.391,0,1,0,9.391,9.391A9.419,9.419,0,0,0,9.391,0Zm3.287,5.635a1.444,1.444,0,0,1,1.409,1.409,1.409,1.409,0,1,1-2.817,0A1.444,1.444,0,0,1,12.678,5.635Zm-6.574,0A1.444,1.444,0,0,1,7.513,7.043,1.444,1.444,0,0,1,6.1,8.452,1.444,1.444,0,0,1,4.7,7.043,1.444,1.444,0,0,1,6.1,5.635Zm3.287,9.391a5.594,5.594,0,0,1-5.259-3.756H14.65A5.594,5.594,0,0,1,9.391,15.026Z" />
            </g>
        </svg>
    );
};

export default Emoji;
