import React, { FC } from 'react';
import styled from 'styled-components';
import ChatInput from './ChatInput/ChatInput';
import Messages from './Messages/Messages';

const ChatBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background: ${props => props.theme.colors.darkBlueGrayBackground};
    height: calc(100vh - 56px);
`;

const ChatBody: FC = () => {
    return (
        <ChatBodyContainer>
            <Messages />
            <ChatInput />
        </ChatBodyContainer>
    );
};

export default ChatBody;
