import React, { FC } from 'react';
import { IconProps } from './types';

export const ChatIcon: FC<IconProps> = ({ className }) => {
    return (
        <svg
            viewBox="0 0 20 20"
            className={className}
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M21,11.5a8.38,8.38,0,0,1-.9,3.8A8.5,8.5,0,0,1,12.5,20a8.38,8.38,0,0,1-3.8-.9L3,21l1.9-5.7A8.38,8.38,0,0,1,4,11.5,8.5,8.5,0,0,1,8.7,3.9,8.38,8.38,0,0,1,12.5,3H13a8.48,8.48,0,0,1,8,8Z"
                transform="translate(-2 -2)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                opacity="0.8"
            />
        </svg>
    );
};

export default ChatIcon;
