import React, { FC } from "react";
import { IconProps } from "./types";

export const ArrowLeft: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 8 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.54289 12.9571C5.93342 13.3476 6.56658 13.3476 6.95711 12.9571C7.34763 12.5666 7.34763 11.9334 6.95711 11.5429L5.54289 12.9571ZM1 7L0.292893 6.29289C-0.097631 6.68342 -0.097631 7.31658 0.292893 7.70711L1 7ZM6.95711 2.45711C7.34763 2.06658 7.34763 1.43342 6.95711 1.04289C6.56658 0.652369 5.93342 0.652369 5.54289 1.04289L6.95711 2.45711ZM6.95711 11.5429L1.70711 6.29289L0.292893 7.70711L5.54289 12.9571L6.95711 11.5429ZM1.70711 7.70711L6.95711 2.45711L5.54289 1.04289L0.292893 6.29289L1.70711 7.70711Z" />
    </svg>
  );
};

export default ArrowLeft;
