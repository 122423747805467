import React, { FC } from "react";
import Error from "../Icons/Error";
import Success from "../Icons/Success";
import Info from "../Icons/Info";
import { variables } from "../../constants/variables";
import useTranslate from "../../config/language";
import {
  SnackbarWrapper,
  StyledMessageContainer,
  IconWrapper,
} from "./InfoSnakbar";

export const variantIcon = {
  success: Success,
  error: Error,
  info: Info,
};

const errorColor = {
  success: "#38CC03",
  error: "#EF4D65",
  info: variables.colors.lighterOrange,
};

export const GenerateIcon: FC<{
  variant: keyof typeof variantIcon;
}> = ({ variant }) => {
  const Icon = variantIcon[variant];

  return (
    <IconWrapper background={errorColor[variant]}>
      <Icon className={variant === "success" ? "success" : "error"} />
    </IconWrapper>
  );
};

export const InfoSnackbar: FC<{
  className?: string;
  message: string;
  keyword?: string;
  handleClose: () => void;
  open: any;
  variant: keyof typeof variantIcon;
}> = ({ message, keyword, variant, open, handleClose }) => {
  const translate = useTranslate();

  return (
    <>
      <SnackbarWrapper
        initial={{ y: 50 }}
        animate={{ y: 0 }}
        open={open}
        variant={variant}
      >
        {<GenerateIcon variant={variant} />}
        <StyledMessageContainer>
          {translate(keyword)} {message}
        </StyledMessageContainer>
      </SnackbarWrapper>
    </>
  );
};
