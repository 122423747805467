import React, { FC } from "react";
import { Container, Switch, Stick, Bullet } from "./ToggleInput.styles";

const ToggleInput: FC<{
  active: boolean;
  onChange?(): void;
  disabled?: boolean;
  className?: string;
}> = ({ active, onChange, disabled, className }) => {
  return (
    <Container className={className}>
      <Switch
        active={active}
        onClick={() => {
          if (disabled) return;
          onChange && onChange();
        }}
        disabled={disabled}
      >
        <Stick
          animate
          active={active}
          className={
            active ? "switch-stick switch-stick-active" : "switch-stick"
          }
        />
        <Bullet
          animate
          active={active}
          className={
            active ? "switch-bullet switch-bullet-active" : "switch-bullet"
          }
        />
      </Switch>
    </Container>
  );
};

export default ToggleInput;
