import React, { FC, useState } from 'react';
import Emoji from '../../../components/Icons/Emoji';
import styled from 'styled-components';
import { rgba } from 'polished';
import { emojis } from './emojiList';
import ClickAwayListener from '../../../helpers/hooks/clickawayListener';

const EmojiArea = styled.div`
    margin-right: 8px;
    padding: 2px 0;
    display: flex;
    align-items: center;
    position: relative;
`;

const EmojiIconContainer = styled.div`
    color: ${props => rgba(props.theme.colors.white, 0.2)};
    width: 20px;
    height: 20px;
    cursor: pointer;
    user-select: none;
    &:active {
        opacity: 0.7;
    }
`;

const EmojisContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 254px;
    height: 255px;
    position: absolute;
    bottom: 34px;
    left: -7px;
    border-radius: 4px;
    background: #dadada14 0 0 no-repeat padding-box;
    backdrop-filter: blur(10px);
    padding: 10px;
    flex-wrap: wrap;
`;

const StyledEmojiWrapper = styled.div`
    height: 28px;
    width: 28px;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
`;

const StyledEmoji = styled.img`
    &:active {
        opacity: 0.8;
        transform: scale(1.1);
    }
`;

const ChatEmojis: FC<{ value: string; setValue(value: string): void }> = ({
    value,
    setValue,
}) => {
    const [openEmoji, setOpenEmoji] = useState(false);

    const handleSelectEmoji = (emojiId: number) => {
        setValue(value + ` {${emojiId}} `);
    };

    return (
        <EmojiArea>
            <EmojiIconContainer onMouseDown={() => setOpenEmoji(!openEmoji)}>
                <Emoji />
            </EmojiIconContainer>
            {openEmoji && (
                <ClickAwayListener onClickAway={() => setOpenEmoji(false)}>
                    <EmojisContainer>
                        {emojis.map(emoji => {
                            return (
                                <StyledEmojiWrapper key={emoji.id}>
                                    <StyledEmoji
                                        src={emoji.path}
                                        alt="emoji"
                                        onClick={e =>
                                            handleSelectEmoji(emoji.id)
                                        }
                                    />
                                </StyledEmojiWrapper>
                            );
                        })}
                    </EmojisContainer>
                </ClickAwayListener>
            )}
        </EmojiArea>
    );
};

export default ChatEmojis;
