import React, { useRef, useEffect, FC } from "react";
import styled from "styled-components";

function useOutsideAlerter(ref: any, onClickAway: any) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickAway]);
}

const ClickAwayDiv = styled.div`
  //   flex: 1;
  //   overflow: auto;
`;

const ClickAwayListener: FC<{ onClickAway?(): void; className?: string }> = ({
  children,
  onClickAway,
  className,
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickAway);

  return (
    <ClickAwayDiv ref={wrapperRef} className={className}>
      {children}
    </ClickAwayDiv>
  );
};

export default ClickAwayListener;
