import React, { memo } from "react";
import ActionBoard from "./ActionBoard";
import { useBoards } from "../../../../config/store/state/app.state";
import {
  useBoardAddedService,
  useBoardRemovedService,
} from "../../../../config/store/services";

const BOARD_ONE_PANEL_INDEX = 3;
const BOARD_TWO_PANEL_INDEX = 4;

const BALANCE_TYPE = 2;

const FreeBetBoardContainer = () => {
  const boards = useBoards();
  const handleAddBoard = useBoardAddedService();
  const handleRemoveBoard = useBoardRemovedService();

  const firstBoard = boards.filter(
    (board) => board.PanelIndex === BOARD_ONE_PANEL_INDEX
  )[0];
  const secondBoard = boards.filter(
    (board) => board.PanelIndex === BOARD_TWO_PANEL_INDEX
  )[0];
  const boardsLength = boards.filter(
    (board) =>
      board.PanelIndex === BOARD_ONE_PANEL_INDEX ||
      board.PanelIndex === BOARD_TWO_PANEL_INDEX
  ).length;

  return (
    <>
      {firstBoard && (
        <ActionBoard
          activeBoards={boardsLength}
          boardIndex={firstBoard.PanelIndex}
          BalanceType={BALANCE_TYPE}
          addBoard={() => handleAddBoard(BOARD_TWO_PANEL_INDEX)}
          removeBoard={() => handleRemoveBoard(BOARD_ONE_PANEL_INDEX)}
        />
      )}
      {secondBoard && (
        <ActionBoard
          activeBoards={boardsLength}
          boardIndex={secondBoard.PanelIndex}
          BalanceType={BALANCE_TYPE}
          addBoard={() => handleAddBoard(BOARD_ONE_PANEL_INDEX)}
          removeBoard={() => handleRemoveBoard(BOARD_TWO_PANEL_INDEX)}
          delay={200}
        />
      )}
    </>
  );
};

export default memo(FreeBetBoardContainer);
