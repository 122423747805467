import React, { memo } from "react";
import List from "./List";
import { useMyBets } from "./configs/store/state";
import { StyledCurrentList, StyledScrollableTable } from "./styles";

const MyBetsList = () => {
  const myBets = useMyBets();
  return (
    <StyledCurrentList>
      <StyledScrollableTable>
        {myBets && <List items={myBets} />}
      </StyledScrollableTable>
    </StyledCurrentList>
  );
};

export default memo(MyBetsList);
