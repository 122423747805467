import React, { FC } from "react";
import { IconProps } from "./types";

export const Success: FC<IconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.7031 4.83496L6.54097 12.2864L3.28613 8.90264"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Success;
