export const emojis = [
    { path: '/images/emojis/0.svg', id: 0 },
    { path: '/images/emojis/1.svg', id: 1 },
    { path: '/images/emojis/2.svg', id: 2 },
    { path: '/images/emojis/3.svg', id: 3 },
    { path: '/images/emojis/4.svg', id: 4 },
    { path: '/images/emojis/5.svg', id: 5 },
    { path: '/images/emojis/6.svg', id: 6 },
    { path: '/images/emojis/7.svg', id: 7 },
    { path: '/images/emojis/8.svg', id: 8 },
    { path: '/images/emojis/9.svg', id: 9 },
    { path: '/images/emojis/10.svg', id: 10 },
    { path: '/images/emojis/11.svg', id: 11 },
    { path: '/images/emojis/12.svg', id: 12 },
    { path: '/images/emojis/13.svg', id: 13 },
    { path: '/images/emojis/14.svg', id: 14 },
    { path: '/images/emojis/15.svg', id: 15 },
    { path: '/images/emojis/16.svg', id: 16 },
    { path: '/images/emojis/17.svg', id: 17 },
    { path: '/images/emojis/18.svg', id: 18 },
    { path: '/images/emojis/19.svg', id: 19 },
    { path: '/images/emojis/20.svg', id: 20 },
    { path: '/images/emojis/21.svg', id: 21 },
    { path: '/images/emojis/22.svg', id: 22 },
    { path: '/images/emojis/23.svg', id: 23 },
    { path: '/images/emojis/24.svg', id: 24 },
    { path: '/images/emojis/25.svg', id: 25 },
    { path: '/images/emojis/26.svg', id: 26 },
    { path: '/images/emojis/27.svg', id: 27 },
    { path: '/images/emojis/28.svg', id: 28 },
    { path: '/images/emojis/29.svg', id: 29 },
    { path: '/images/emojis/30.svg', id: 30 },
    { path: '/images/emojis/31.svg', id: 31 },
    { path: '/images/emojis/32.svg', id: 32 },
    { path: '/images/emojis/33.svg', id: 33 },
    { path: '/images/emojis/34.svg', id: 34 },
    { path: '/images/emojis/35.svg', id: 35 },
    { path: '/images/emojis/36.svg', id: 36 },
    { path: '/images/emojis/37.svg', id: 37 },
    { path: '/images/emojis/38.svg', id: 38 },
    { path: '/images/emojis/39.svg', id: 39 },
    { path: '/images/emojis/40.svg', id: 40 },
    { path: '/images/emojis/41.svg', id: 41 },
    { path: '/images/emojis/42.svg', id: 42 },
    { path: '/images/emojis/43.svg', id: 43 },
    { path: '/images/emojis/44.svg', id: 44 },
    { path: '/images/emojis/45.svg', id: 45 },
    { path: '/images/emojis/46.svg', id: 46 },
    { path: '/images/emojis/47.svg', id: 47 },
    { path: '/images/emojis/48.svg', id: 48 },
    { path: '/images/emojis/49.svg', id: 49 },
    { path: '/images/emojis/50.svg', id: 50 },
    { path: '/images/emojis/51.svg', id: 51 },
    { path: '/images/emojis/52.svg', id: 52 },
    { path: '/images/emojis/53.svg', id: 53 },
    { path: '/images/emojis/54.svg', id: 54 },
    { path: '/images/emojis/55.svg', id: 55 },
    { path: '/images/emojis/56.svg', id: 56 },
    { path: '/images/emojis/57.svg', id: 57 },
    { path: '/images/emojis/58.svg', id: 58 },
    { path: '/images/emojis/59.svg', id: 59 },
    { path: '/images/emojis/60.svg', id: 60 },
    { path: '/images/emojis/61.svg', id: 61 },
    { path: '/images/emojis/62.svg', id: 62 },
    { path: '/images/emojis/63.svg', id: 63 },
];
