import { useCallback } from 'react';
import { emojis } from './ChatEmojis/emojiList';

export const useParseDisplayMessage = (parseGifs?: boolean) => {
    return useCallback(
        (text: string) => {
            const matches = text.match(/[^{}]+(?=})/g) || [];

            for (let match of matches) {
                if (
                    emojis.filter(emoji => emoji.id.toString() === match)
                        .length > 0
                ) {
                    text = text.replace(
                        `{${match}}`,
                        `<img alt="emoji" class="chat-emoji" src="/images/emojis/${match}.svg">`
                    );
                }
            }

            if (text.indexOf('giphy.com') >= 0 && parseGifs) {
                text = `<img alt="gif" class="chat-giff" src="${text}">`;
            }

            return text;
        },
        [parseGifs]
    );
};

export const useParseSendMessage = () => {
    return useCallback((text: string) => {
        if (
            text.indexOf('<img alt="emoji" class="chat-emoji" src=') >= 0 &&
            text.indexOf('.svg">') > 0
        ) {
            text = text
                .split(
                    '<img alt="emoji" class="chat-emoji" src="/images/emojis/'
                )
                .join('{')
                .split('.svg">')
                .join('}')
                .split('&nbsp;')
                .join('');
        }
        return text;
    }, []);
};
